import React, { useState, useEffect, useContext, useCallback } from 'react'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import { context } from '../../../store'
import callAPI from '../../../lib/callAPI'

import {
  AccountLayout, AccountSubMenu,
  AccountGiftListForm } from '../../../components'

const AccountGiftListCreateTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext
  const { store } = useContext(context)

  return (
    <AccountLayout lang={lang} switcher={page}>
  		<div className="wrapper d-flex flex-column flex-md-row">

        <AccountSubMenu lang={lang} />

  			<div className="content">
          <AccountGiftListForm lang={lang} />
        </div>

  		</div>
    </AccountLayout>
  )
}

export default AccountGiftListCreateTemplate
